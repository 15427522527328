import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import options from "../../components/richtext";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Video from "../../components/video";
import PropTypes from "prop-types";

const Artist = ({ data }) => {
  return (
      <div>
      <Seo title={data.contentfulArtist.name} />
      <div class="row row-cols-1 row-cols-md-2">
        <div class="col">
          <img
            alt=""
            class="img-fluid"
            src={data.contentfulArtist.image.fixed.src}
          />
            <Video src={data.contentfulArtist.musicVideo} />
        </div>
        <div class="col px-5 my-5">
          <h2>{data.contentfulArtist.name}</h2>
                              <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{
              __html:
                data.contentfulArtist.content
                  .childrenMarkdownRemark[0].html
            }}
          />
        </div>
      </div>
          </div>
  );
};

export default Artist;

export const query = graphql`
  query($slug: String) {
    contentfulArtist(slug: { eq: $slug }) {
      musicVideo
      content {
        childrenMarkdownRemark {
          html
        }
      }
      name
      image {
        fixed(width: 800) {
          src
        }
      }
    }
  }
`;
